// export const baseURL = 'http://localhost:8000/api';
export const baseURL = 'https://app.api.elyzian.in/api';

export const companyId = localStorage.getItem('compId');
// export const companyId = '650c64be035ef7dd6769dbc0';


// export const config = {
//   Accept: 'application/json',
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// };
