import React, { useEffect, useState } from 'react'
import logo from '../Assets/lolo.png'
import small from '../Assets/elyzian.png'
import { makeStyles } from '@mui/styles';
import { FiClock } from "react-icons/fi";
import { GoDeviceCameraVideo } from "react-icons/go";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import SimpleModal from './SimpleModal';
import { FaRegCalendarCheck } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import axios from 'axios';
import { baseURL, companyId } from '../Headers';
import { useNavigate,useParams } from 'react-router-dom';

const useStyles = makeStyles({
   
    
    '& .MuiPickersCalendarHeader-root': {
      /* Your styles for MuiDateCalendar-root */
      width: '500px', /* Example width */
      height: '500px', /* Example height */
    },

  
  });

  function formatTime(hour, minute) {
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert 0 to 12
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    return `${formattedHour}:${formattedMinute} ${ampm}`;
  }
  
  function Timings({ onSelectTime }) {
    const [selectedTime, setSelectedTime] = useState(null);
  
    // Generate timings from 10 AM to 6 PM with 30-minute intervals
    const timings = [];
    for (let hour = 10; hour <= 18; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        timings.push(formatTime(hour, minute));
      }
    }
  
    return (
      <div className='flex gap-3 overflow-x-auto px-4'>
        {timings.map((time, index) => (
          <div
            className={`w-[100px] border-blue-500 mb-4 medium hover:cursor-pointer flex-none rounded-lg text-sm regular h-[40px] border items-center justify-center flex ${
              time === selectedTime ? 'bg-blue-500 text-white' : 'text-blue-500'
            }`}
            key={index}
            onClick={() => {
              setSelectedTime(time);
              onSelectTime(time);
            }}
          >
            {time}
          </div>
        ))}
      </div>
    );
  }
  

function Appointment() {
  const { id } = useParams();
  const [value, setValue] = useState(dayjs());
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isEmailValid = email.length > 10 ? emailPattern.test(email) : true;

  const formatDate = (date) => {
    return date.format('DD-MM-YYYY');
  };
  
    const [showUploadModal, setShowUploadModal] = useState(false);

    const [selectedTime, setSelectedTime] = useState(null);

    const [date, setDate] = useState(dayjs().format('DD-MM-YYYY'))
    const navigate = useNavigate();


    const handleSelectTime = (time) => {
      setSelectedTime(time);
    };

    // console.log('day',dayjs().format('DD-MM-YYYY'));

    const [appointmentHeading, setAppointmentHeading] = useState('Schedule a meeting with Elyzian.');
    const [showAppointment, setshowAppointment] = useState(true);
    const [companyImage, setCompanyImage] = useState(logo);
    const [smallImage, setSmallImage] = useState(small);
    const [loading, setLoading] = useState(true);
  
    const handlegetAppointmentSettings = async () => {
      const response = await axios.get(`${baseURL}/leads/get-appointment-setting/${id}`);
      if (response?.data?.status === 'success') {
        if (response?.data?.data) {
          setAppointmentHeading(response?.data?.data?.appointmentHeading);
          setshowAppointment(response?.data?.data?.showAppointment);
    
          setCompanyImage(response?.data?.data?.companyImage);
          setSmallImage(response?.data?.data?.smallImage);
        }
     
        setLoading(false);
      }
      // console.log('appointment settings',response);
    };
  // console.log('appointmentHeading',appointmentHeading,smallImage);
    useEffect(() => {
      handlegetAppointmentSettings();
    }, []);

const handleappointment = async() => {

  const formData = {
    date:date, 
    companyId:id,
    time: selectedTime,
    name:name,
    email:email,
    phone: phone,
    status: 'Approved',
  };

  const response = await axios.post(`${baseURL}/leads/appointment/${id}`,formData);
  console.log('apoointment response',response);

  if (response.status === 200){
    setShowUploadModal(false);
    setName('');
    setPhone('');
    setEmail('');
    setSelectedTime(null);
    // navigate(`/success/${id}`);
    navigate(`/success`, {
      state: {
        heading: "Appointment Booked", 
        subHeading: "Thank you for booking your appointment, you will recieve a confirmation shortly.", 
        navigateBackPath: `/book-appointment/${id}`          
      }
    });
  }
}


const dateChange = (val) => {
   setValue(val);
   setDate(formatDate(val));

}

  const classes = useStyles();
  return (
    <div className='w-full h-full flex pb-10 sm:my-0 justify-center'>
        <div className='sm:top-[30px]  card_box mt-6 2xl:my-10 h-max sm:h-[750px] w-[95%] sm:w-[70%] 2xl:w-[60%] border rounded-lg shadow-lg appointbox relative'>
        <span className=' rotate-90 tag'> </span>
        <div className='flex flex-col sm:flex-row h-full'>
            <div className='w-full sm:w-[35%] border-r h-full flex flex-col'>
               <div className='h-[25%] py-6 sm:py-0 flex items-center justify-center border-b'>
                <img className='w-[190px]' src={companyImage} alt="" />
               </div>
               <div className='h-[75%] p-[30px] flex flex-col gap-3'>
               <img className='w-[60px] rounded-full' src={smallImage} alt="" />
               <span className='medium text-gray-500'>Book Appointment </span>
               <span className='medium xl:text-2xl 2xl:text-3xl tracking-tight'>{appointmentHeading} </span>
               <span className='medium text-gray-500 mt-3 text-xs sm:text-sm 2xl:text-base flex items-center gap-2'><FiClock />Pick a slot </span>
               <span className='medium text-gray-500 mt-1 text-xs sm:text-sm 2xl:text-base flex items-center gap-2'><GoDeviceCameraVideo />Details provided upon confirmation. </span>

               <span className='regular text-blue-600 text-xs sm:text-sm 2xl:text-base mt-4 flex items-center gap-2'>Please select your available time slot for meeting. </span>


               </div>
            </div>
            <div className='w-full sm:w-[65%] p-[30px]'>
            <span className='medium '>Select a Date & Time </span>
            <LocalizationProvider className=" " dateAdapter={AdapterDayjs}>
                <DemoContainer  components={['DateCalendar', 'DateCalendar']}>
                
                    <DateCalendar sx={{
                        width: '100%',
                        maxHeight: '500px',
                        height:'500px',



                        '& .MuiStack-root ':{
                            width: '100%', 
                            minHeight: '500px',

                        },
                        // '& .MuiDateCalendar-root':{
                        //     maxHeight: '900px',
                        //     height: '400px'

                        // },

                         '& .MuiPickersCalendarHeader-root': {
                            width: '100%', 
                            marginBottom: '30px',
                          },
                      '& .MuiPickersCalendarHeader-label':{
                        fontSize: '14px',
                        fontFamily: 'GraphikMedium'
                      },
                      '& .MuiDayCalendar-weekDayLabel':{
                        fontSize: '16px',
                        color: '#1976D2',
                        width: '50px',
                        height: '50px',
                        fontFamily: 'GraphikMedium'
                      },
                      '& .MuiPickersDay-root':{
                        width: '50px',
                        fontSize: '13px',
                        height: '50px',
                      },
                      '& .css-1t0788u-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition': {
                        display: 'block',
                        position: 'relative',
                        // border: '2px solid red',
                        height: '320px',
                        overflowX: 'hidden',
                        minHeight: '240px', // Adjust as needed
                      },
                    '& .css-gv71db-MuiDateCalendar-root':{
                        height:'500px',
                    },
                    '& .MuiYearCalendar-root': {
                        border: '1px solid #e0e6ee',
                        boxShadow: '0px 0px 15px -8px rgba(150,150,150,1)',
                        borderRadius: '8px',
                        width: '100%',
                    }
                    }}
                     value={value} onChange={(newValue) => dateChange(newValue)} />
                </DemoContainer>
            </LocalizationProvider>
                <div className='w-full overflow-x-auto'>
                <Timings onSelectTime={handleSelectTime} />

                  {/* Selected Time */}
                  {/* {selectedTime && <div>Selected Time: {selectedTime}</div>} */}
                </div>
                <div className='flex mt-8 justify-end'>
                <button
                  type="button"
                  onClick={() => setShowUploadModal(true)}
                  disabled={ selectedTime === null }
                  className={`flex disabled:bg-gray-200 disabled:border-gray-50 disabled:cursor-not-allowed disabled:text-gray-700 h-8 justify-center gap-[5px] items-center w-[130px] text-[0.84rem] tracking-wide transition-all  focus:ring-[3px] hover:shadow focus:bg-[#1E47F6]  hover:bg-[#1E47F6]  focus:ring-blue-200    bg-[#466EFF]  text-white hover:transition hover:delay-200 buttonShadow  border-[0.5px] border-blue-700 disabled:cursor-not-allowed rounded-lg shadow-md medium p-2 disabled:bg-[#466eff8b] disabled:hover:shadow-none disabled:shadow-none`}
                >

                  <span className="text-[0.84rem] medium ">Proceed</span>
                </button>
                </div>
            </div>
        </div>
            
        </div>
        <SimpleModal padding="0"  open={showUploadModal} setOpen={setShowUploadModal}>
          <div className='w-[380px] h-[600px] sm:w-[800px] sm:h-[600px] p-4 pt-9 sm:pt-12 sm:p-12 flex flex-col gap-5'>
           <span className='text-xl medium'>Enter your details</span>

           <div className='flex gap-[40%]'>
            <span className='flex items-center gap-2 medium text-sm sm:text-xl'><FaRegCalendarCheck className='text-sm sm:text-3xl text-blue-500' />{formatDate(value)}</span>
            <span className='flex items-center gap-2 medium text-sm sm:text-xl'><MdAccessTime className='text-sm sm:text-4xl text-blue-500' />{selectedTime}</span>
           </div>
           <div className='mt-6'> 
          <p className="medium text-[14px] mb-1 text-[#292E36]">Name</p>        
          <input
            type="text"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`resize-none overflow-hidden focus:ring-4 shadow-sm
            shadow-gray-200 border block w-[100%]      
            text-gray-900 text-sm rounded-lg outline-none h-10
            focus:border-[1px] focus:ring-blue-200 focus:border-blue-500 
            p-1.5 pl-2 placeholder:text-[12px] placeholder:tracking-wide` 
              }
          />
          </div>

           <div>
          <p className="medium text-[14px] mb-1 text-[#292E36]">Phone</p>        
          <input
            type="text"
            placeholder="Enter Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={`resize-none overflow-hidden focus:ring-4 shadow-sm
            shadow-gray-200 border block w-[100%]      
            text-gray-900 text-sm rounded-lg outline-none h-10
            focus:border-[1px] focus:ring-blue-200 focus:border-blue-500 
            p-1.5 pl-2 placeholder:text-[12px] placeholder:tracking-wide` 
              }
          />
          </div>

           <div>
          <p className="medium text-[14px] mb-1 text-[#292E36]">Email</p>        

          <input
            type="text"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`${isEmailValid ? `resize-none overflow-hidden focus:ring-4 shadow-sm
            shadow-gray-200 border block w-[100%]      
            text-gray-900 text-sm rounded-lg outline-none h-10
            focus:border-[1px] focus:ring-blue-200 focus:border-blue-500 
            p-1.5 pl-2 placeholder:text-[12px] placeholder:tracking-wide` 
              : `resize-none overflow-hidden focus:ring-4 shadow-sm
            shadow-gray-200 border block w-[100%]      
            text-gray-900 text-sm rounded-lg outline-none h-10
            focus:border-[1px] focus:ring-red-200 focus:border-red-500 
            p-1.5 pl-2 placeholder:text-[12px] placeholder:tracking-wide`
            }`}
          />
          </div>

          <div className='mt-7 flex justify-end'>
          <button
            type="button"
            onClick={() => handleappointment()}
            // disabled={disabled}
            className={`flex h-8 justify-center gap-[5px] items-center min-w-[160px] text-[0.84rem] tracking-wide transition-all  focus:ring-[3px] hover:shadow focus:bg-[#1E47F6]  hover:bg-[#1E47F6]  focus:ring-blue-200    bg-[#466EFF]  text-white hover:transition hover:delay-200 buttonShadow  border-[0.5px] border-blue-700 disabled:cursor-not-allowed rounded-lg shadow-md medium p-2 disabled:bg-[#466eff8b] disabled:hover:shadow-none disabled:shadow-none`}
          >

            <span className="text-[0.84rem] medium ">Confirm Booking</span>
          </button>
          </div>
          </div>
      </SimpleModal>
    </div>
  )
}

export default Appointment
