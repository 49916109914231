import React from 'react';
import { FaRegSadTear, FaRegMeh, FaRegSmile, FaRegGrinHearts } from 'react-icons/fa';
import { BsFillLightningFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';


const FeedbackComponent = ({ latestPurchase,feedback,themeColor, googleLink ,review,handleFeedback  }) => {

    const navigate = useNavigate();

    // const handleFeedback = () => {
    //     navigate('/loyalty/feedback', {
    //         state: {
    //             feedback,
    //             themeColor,
    //             googleLink,
    //             review
    //         }
    //     });
    // };

  return (
    <div className="flex justify-center py-6">
    <div className="feedback-component bg-white flex flex-col items-center p-4 rounded-md w-[340px]">

      <span className="text-base medium ">How was your experience?</span>

      <div className="emoji-feedback flex gap-8 items-center my-4">
        <div onClick={handleFeedback} className="emoji-option flex flex-col items-center cursor-pointer">
        <span className="text-3xl">        😭
        </span>
          <span className="text-xs medium text-[#555c67] ">Sad</span>
        </div>

        <div onClick={handleFeedback} className="emoji-option flex flex-col items-center cursor-pointer">
        <span className="text-3xl ">🙁</span>
        
          <span className="text-xs medium text-[#555c67] ">Meh</span>
        </div>

        <div onClick={handleFeedback} className="emoji-option flex flex-col items-center cursor-pointer">
        <span className="text-3xl ">😐</span>  
                <span className="text-xs medium text-[#555c67] ">Okay</span>
        </div>

        <div onClick={handleFeedback} className="emoji-option flex flex-col items-center cursor-pointer">
        <span className="text-3xl ">😄</span>  
                <span className="text-xs medium text-[#555c67] ">Good</span>
        </div>

        <div onClick={handleFeedback} className="emoji-option flex flex-col items-center cursor-pointer">
 <span className="text-3xl ">😍</span>
           <span className="text-xs medium text-[#555c67] ">Loved it!</span>
        </div>
      </div>

      <div className="flex flex-col items-center gap-1  text-gray-600 text-sm">
        <span className="medium text-purple-500">Purchase made</span>
        <span> 
            ₹ {latestPurchase?.amount} |  🕐  {new Date(latestPurchase?.date).toLocaleString('en-GB', {
                day: '2-digit', 
                month: 'short', 
                year: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true
            })}
        </span>
        
      </div>

      <div className="bonus-points mt-4 w-full flex justify-center items-center text-xs bg-[#fcf5ff] text-[#8e44ad] p-1 px-5 rounded-md">
      <BsFillLightningFill className="" />
        <p>
          <span className="medium">Earn {feedback} Bonus Pts</span> on giving feedback
        </p>
      </div>
    </div>
    </div>
  );
};

export default FeedbackComponent;
