import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { SlLock } from 'react-icons/sl';
import { BsFillLightningFill } from "react-icons/bs";
import { HiOutlineLockOpen } from "react-icons/hi2";
import cafe from '../Assets/cafe.jpg';
import cafelogo from '../Assets/cafelogo.jpeg';
import { CgLockUnlock } from "react-icons/cg";

function LoyaltyPreview({
  loyaltyLogo,
  coverImage,
  sections,
  spendPoints,
  themeColor, 
  title,
  unlockedRewards,
  pointsToNextReward,
}) {
  console.log('points to next reqrd',pointsToNextReward);
  
  return (
    <div className="w-max flex flex-col items-center  mt-4 ">
      <div className="self-start medium mb-4">
        Loyalty rewards
      </div>
      <div className="bg-green-100 text-green-600 medium text-center w-[60%] rounded-t-lg p-[5px] text-sm flex items-center gap-2 justify-center "> <CgLockUnlock className="-rotate-12" /> {unlockedRewards} Rewards unlocked 🎉</div>

      <div className="bg-[#fcf5ff] text-[#8e44ad] semibold  text-center text-xs w-full -mb-2 rounded-t-lg py-3 pb-4 flex items-center gap-2 justify-center "> <BsFillLightningFill className="" /> Spend ₹{pointsToNextReward === null ? ' 0' : pointsToNextReward} to unlock the next reward</div>
      <div style={{ backgroundColor: themeColor }} className="w-[346px] sm:w-[358px] transition-all duration-200 h-max pb-4  border rounded-[12px] bg-[] overflow-hidden">
        <div className="w-full h-[240px] relative">
          {/* <TransformWrapper
            initialScale={1}
            wheel={{ disabled: true }} // Disable zooming on scroll
            pan={{ disabled: true }}   // Disable panning
            pinch={{ disabled: true }} // Disable pinch-to-zoom (if using touch)
            zoom={{ disabled: true }}
          >
            {({
              zoomIn, zoomOut, resetTransform, ...rest 
            }) => (
              <TransformComponent> */}
                <img
                  src={coverImage || cafe}
                  alt="Preview"
                  className="w-full h-[] "
                  style={{
                    clipPath: 'ellipse(234px 215px at 76% 3%)',
                  }}
                />
              {/* </TransformComponent>
            )}
          </TransformWrapper> */}
          <div className="absolute left-6 bottom-4 bg-white rounded-full w-28 h-28 overflow-clip object-cover flex items-center justify-center">
            <img
              src={loyaltyLogo || cafelogo}
              alt="Cafe Logo"
              className="w-full aspect-auto"
            />
          </div>
        </div>
        <div className="px-5">
          <div className="flex flex-col gap-2">
            <span className="medium text-2xl text-white">{title}</span>
            <span className="text-[#ffffffcf] text-">Get rewarded on every purchase</span>
            <span className="text-[#ffffffcf] text-sm">
              ₹ 1 Spent =
              {' '}
              {spendPoints}
              {' '}
              Point
            </span>

          </div>
          <div>
            {sections?.map((e) => (
              <div style={{ borderBottom: '0.7px dashed' }} className={`min-h-24 w-full flex mt-4 p-2 ${ e?.locked ? 'border-[#ffffff99]  text-[#ffffff99]' : 'text-white border-white' } `}>
                <div className="flex items-center  w-[40%] gap-2">
                  <span className=" text-lg medium">{e?.points}</span>
                  <span className=" text-xs">PTS</span>
                </div>
                <div className="w-[60%]">
                  <div className="flex flex-col gap-1 ">
                    <div className="flex items-center">
                      <span className=" text-base">{e?.pointdesc}</span>
                      <span className=" text-">{ e?.locked ? <SlLock /> : <HiOutlineLockOpen  className="text-base"/>}</span>
   
                    </div>
                    {e?.minSpendSwitch && (
                    <span className=" text-[10px] ">
                      *Minimum purchase of ₹
                      {' '}
                      {e?.minAmount}
                      {' '}
                      required to redeem reward.
                    </span>
                    )}
                   
                  </div>
                </div>
              </div>
            ))}
           
          </div>
{/* 
          <div style={{ borderBottom: '0.7px dashed' }} className="h-24 w-full flex mt-4 p-2  border-[#ffffff99]  text-[#ffffff99]">
            <div className="flex items-center  w-[40%] gap-2">
              <span className=" text-lg medium">1000</span>
              <span className=" text-xs">PTS</span>
            </div>
            <div className="w-[60%]">
              <div className="flex flex-col gap-1 ">
                <div className="flex items-center">
                  <span className=" text-base">75₹ off on entire purchase</span>
                  <span className=" text-"><SlLock /></span>

                </div>
                <span className=" text-[10px] ">*Minimum purchase of ₹499 required to redeem reward.</span>
              </div>
            </div>
          </div> */}

          <div className="h-24 w-full flex mt-4 p-2 border-dashed border border-[#ffffffbc]  rounded-lg text-[#ffffff99]">
            <div className="flex flex-col gap- justify-center pl-3">
              <span className="text-xs text-white medium mb-1">Terms & Conditions</span>
              <span className="text-[#ffffffcf] text-[10px]">*Minimum purchase required.</span>
              <span className="text-[#ffffffcf] text-[10px]">*2 offers cannot be clubbed.</span>

            </div>
          </div>
        </div>
     
      </div>

    </div>
  );
}

export default LoyaltyPreview;
