import React from 'react'
import e from '../Assets/e.png'
import { FaInstagram, FaFacebook, FaLinkedinIn , FaGlobe} from 'react-icons/fa';  

function LoyaltyFooter() {
  return (
    <div className='w-full flex flex-col gap-3 items-center my-8'>
      <span className="text-sm text-gray-500">Powered by</span>
      <div className="flex items-center gap-2 mb-2">
        <img src={e} className="h-8 rounded-[2px] aspect-square" alt="" />
        <span className="text-xl text-[#1A202c] medium">Elyzian</span>
        </div>
        <span className="text-sm text-gray-500">Manage your business <span className='text-teal-500 medium'>smoothly</span>  !</span>
        <div className="flex gap-4 justify-center items-center mt-2">
      {/* Instagram */}
      <a href="https://www.instagram.com/elyzianai/" target="_blank" rel="noopener noreferrer">
        <FaInstagram className="text-gray-500 hover:text-gray-900 text-2xl  " />
      </a>

      {/* Facebook */}
      <a href="https://www.facebook.com/profile.php?id=61557740607357" target="_blank" rel="noopener noreferrer">
        <FaFacebook className="text-gray-500 hover:text-gray-900 text-2xl  " />
      </a>

      {/* LinkedIn */}
      <a href="https://www.linkedin.com/company/elyzian-ai/" target="_blank" rel="noopener noreferrer">
        <FaLinkedinIn className="text-gray-500 hover:text-gray-900 text-2xl  " />
      </a>
      <a href="https://elyzian.in/" target="_blank" rel="noopener noreferrer">
        < FaGlobe className="text-gray-500 hover:text-gray-900 text-xl  " />
      </a>
    </div>

    </div>
  )
} 

export default LoyaltyFooter
