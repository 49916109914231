import React, { useEffect, useState } from 'react'
import { BsChatText } from "react-icons/bs";
import { MdKeyboardBackspace } from "react-icons/md";
import { FaCircleChevronRight } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { GoStar } from 'react-icons/go';
import medal from '../Assets/medal.png';

import { BsFillLightningFill } from "react-icons/bs";

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoyaltyFooter from './LoyaltyFooter';
import axios from 'axios';
import { baseURL } from '../Headers';
import toast from 'react-hot-toast';

function Referral() {
  const {contactId,companyId} = useParams();
  const [loading, setLoading] = useState(false)

    const [selected, setSelected] = useState(null);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('')
    const navigate = useNavigate();

    // const { themeColor, referal,  loyaltyLogo,
    //     title,} = location.state || {};

  const [themeColor, setThemeColor] = useState('#1c4c3c');
  const [referal, setreferal] = useState('');

  const [title, setTitle] = useState('Cafe Mysa');
  const [loyaltyLogo, setLoyaltyLogo] = useState('');


  useEffect(() => {
    handleGetLoyaltySettings();
  }, []);

    const handleGetLoyaltySettings = async () => {
      try {
        setLoading(true);  
      
        const res = await axios.get(`${baseURL}/loyalty/get-loyalty-points-for-cx/${contactId}/${companyId}`);
        console.log('getLoyaltySettings res', res);
  
        if (res?.data?.status === 'success') {
          setThemeColor(res.data?.data?.loyaltySettings?.themeColor || themeColor);
          setTitle(res.data?.data?.loyaltySettings?.title)
          // setreferal
          const converters = res.data?.data?.loyaltySettings?.converters || [];
          setreferal(converters.find((item) => item.action === 'refer')?.pointsPerUnit || 100);
          setLoyaltyLogo(res.data?.data?.loyaltySettings?.loyaltyLogo || '');

          setLoading(false);
        } else {
          toast.error(res.data?.message || 'Failed to fetch settings');
        }
      } catch (err) {
        console.error('Error fetching loyalty settings:', err);
        toast.error(err.message);
      }
    };

    // const handleReferToFriend = async () => {
    //   const payload = {
    //     name,
    //     phone
    //   }
    //   try {
    //     setLoading(true);  
      
    //     const res = await axios.post(`${baseURL}/referral/refer-to-friend/${contactId}/${companyId}`,payload);
    //     console.log('getLoyaltySettings res', res);
  
    //     if (res?.data?.status === 'success') {
    //       toast.success('Your points will be added when your friend makes a purchase')
    //       setLoading(false);
    //       navigate(`/success`, {
    //         state: {
    //           heading: `Successfully Referred ${name}`, 
    //           subHeading: "Thank you for referring a friend! Your points will be added once your friend makes a purchase.", 
    //           navigateBackPath: `/loyalty/${contactId}/${companyId}`,       
    //         }
    //       });
    //     } else {
    //       toast.error(res.data?.message || 'Something went wrong');
    //     }
    //   } catch (err) {
    //     console.error('Error refer to friend:', err);
    //     toast.error(err.message);
    //   }
    // };

    const handleReferToFriend = async () => {
      // Validation check for required fields
      if (!name || !phone) {
        toast.error('Please provide both name and phone number.');
        return; // Exit function if validation fails
      }
    
      const payload = {
        name,
        phone
      };
    
      try {
        setLoading(true);  
    
        const res = await axios.post(`${baseURL}/referral/refer-to-friend/${contactId}/${companyId}`, payload);
        console.log('getLoyaltySettings res', res);
    
        if (res?.data?.status === 'success') {
          toast.success('Your points will be added when your friend makes a purchase');
          setLoading(false);
          navigate(`/success`, {
            state: {
              heading: `Successfully Referred ${name}`, 
              subHeading: "Thank you for referring a friend! Your points will be added once your friend makes a purchase.", 
              navigateBackPath: `/loyalty/${contactId}/${companyId}`,       
            }
          });
        } else {
          toast.error(res.data?.message || 'Something went wrong');
        }
      } catch (err) {
        console.error('Error refer to friend:', err);
        toast.error(err.message || 'Failed to refer friend. Please try again later.');
      } finally {
        setLoading(false); // Ensure loading state is reset
      }
    };

    if(loading){
      return <div className='w-screen h-screen overflow-x-hidden flex items-center justify-center'><span class="loader"></span></div>
    }

    return (
        <div className='w-screen min-h-screen flex justify-center overflow-x-hidden  overflow-y-auto'>
      <div className="w-[340px] py-4 bg-white ">
        <div className="py-4 border-b mb-4 flex items-center justify-between">
            <div className="flex items-center gap-2">
               <span className="text-sm bg-black text-white p-[6px] rounded-full">                       
                 <GoStar className="z-10" />
               </span> 
                <span className="text medium">Referral</span>
            </div>
            <div onClick={() => navigate(-1)} className="flex items-center gap-2 cursor-pointer hover:underline">
               <span className="text-sm "><MdKeyboardBackspace /></span> 
                <span className="text-xs">Back</span>
            </div>
        </div>
        <div className="flex justify-center items-center gap-2">
          <div className="  bg-white rounded-full w-16 h-w-16 overflow-clip object-cover">
            <img
              src={loyaltyLogo || ''}
              alt="Cafe Logo"
              className="w-full aspect-auto"
            />
          </div>
          <span className="text-base medium">{title}</span>
          </div>
        <div className="text-center text-xl semibold my-4 mt-4">Refer a friend.</div>
        <div className="text-center text-xs text-gray-600 mb-6">
          Enter the details of a friend you want to make a part of our family.
        </div>
        
        {/* <div className="flex justify-around my-10 gap-4">
          {feedbackOptions.map((option, index) => (
            <div 
              key={index}
              onClick={() => setSelected(index)}
              className={`emoji-option flex flex-col items-center cursor-pointer transition-all duration-300 ease-in-out 
                ${selected === index ? ' text-black ' : 'text-[#555c67]'}`}
            >
              <span className={`text-3xl transition-all duration-300 ease-in-out  flex justify-center ${selected === index ? '  w-[50px] h-[50px] scale-125 bg-gradient-to-b from-emerald-100 to-teal-200 p-2 rounded-full text-black ' : ''}`}>{option.emoji}</span>
              <span className="text-xs medium mt-3">{option.label}</span>
            </div>
          ))}
        </div> */}

          <div className="bonus-points mt-4 w-full flex justify-center items-center text-xs bg-[#fcf5ff] text-[#8e44ad] p-1 px-5 rounded-md">
      <BsFillLightningFill className="" />
        <p>
          <span className="medium">Earn {referal} Bonus Pts</span> on referral
        </p>
      </div>
        <div className='flex flex-col gap-2 mt-4'>
            <div className='text-sm medium'>Name</div>
        <input
          value={name}
          onChange={(e)=>setName(e.target.value)}
          className={`mb- overflow-hidden focus:ring-[2px] shadow-sm
            shadow-gray-200 border block w-[100%] h-10     
            text-gray-900 text-sm rounded-[8px] outline-none 
            focus:border-[1px] focus:ring-blue-200 focus:border-blue-500 
            p-1.5 pl-2 placeholder:text-[12px] placeholder:tracking-wide` 
              }
          placeholder="Enter name"
        />
        </div>
        <div className='flex flex-col gap-2 mt-4'>
            <div className='text-sm medium'>Phone Number</div>
        <input
        value={phone}
        onChange={(e)=>setPhone(e.target.value)}
          className={`mb-6 overflow-hidden focus:ring-[2px] shadow-sm
            shadow-gray-200 border block w-[100%] h-10     
            text-gray-900 text-sm rounded-[8px] outline-none 
            focus:border-[1px] focus:ring-blue-200 focus:border-blue-500 
            p-1.5 pl-2 placeholder:text-[12px] placeholder:tracking-wide` 
              }
          placeholder="Enter phone"
        />
        </div>
        <button 
          onClick={handleReferToFriend}
          style={{ backgroundColor: themeColor }} 
          className="w-full duration-200 text-sm medium ease-in-out text-white py-2 rounded-lg hover:brightness-125 transition-colors">
          Share referral code
        </button>


                    <div className="min-h-24 w-full flex mt-10 p-2 py-3 bg-gray-100  rounded-lg ">
                        <div className="flex flex-col gap- justify-center px-3">
                          <span className="text-sm text-black medium mb-3">How to Redeem Referral Points?</span>
                          <span className="text-[#555c67] text-[12px] leading-4">Give your referral code to the staff on your next purchase and ask them to redeem.</span>
                          {/* <span className="text-[#555c67] text-[10px]">*2 offers cannot be clubbed.</span> */}

                        </div>
                      </div>
                        <div className="mb-10">
                      <LoyaltyFooter />
                      </div>
      </div>
      </div>
    );
}

export default Referral
