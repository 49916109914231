/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { useNavigate,useParams } from 'react-router-dom';

import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import AccountCircle from '@mui/icons-material/AccountCircle';
import { BiSearchAlt } from 'react-icons/bi';
import { BsArrowRightShort } from 'react-icons/bs';
import { FaLocationDot } from 'react-icons/fa6';
import logo from '../Assets/e.png';
import { baseURL } from '../Headers';
// import { makeStyles } from '@material-ui/core';

// const useStyles = makeStyles({
//   myTextfield: {
//     '& .MuiInputBase-input': {
//       color: 'black',
//       borderBottom: '1px solid #E9E3FE',
//       fontSize: '14px',
//       transition: 'border-color 0.3s ease',
//       padding: '12px',
//     },
//     '& .MuiInputBase-input:focus': {
//       borderColor: '#3074DC',
//       borderBottom: '1px solid #3074DC',
//       outline: 'none',
//     },
//     '& .MuiFormLabel-root': {
//       paddingLeft: '5px',
//       fontSize: '15px',
//     },
//   },
// });
// const classes = useStyles();

const Locations = () => {
  // const location = useLocation();
  // const data = location.state;
  const { id } = useParams();
  const navigate = useNavigate();

  


  const [searchValue, setSearchValue] = useState('');
  const [hoverState, setHoverState] = useState(null);
  const [data, setData] = useState([]);

useEffect(() => {
  handleGetLocations();

}, []);

const handleClick = (val) => {
  navigate(`/book-appointment/${val._id}`, {
    state: {
    }, 
  });

};
  const handleGetLocations = async() => {
    const response = await axios.get(`${baseURL}/company/get-locations/${id}`);
    console.log(response,'all locations');
    if(response?.status === 200){
      setData(response?.data?.data);
    }
  }
  // console.log('=>',data,searchValue);
  const handleLogout = () => {
    localStorage.setItem('authToken', '');
    localStorage.setItem('userId', '');
    localStorage.setItem('compId', '');
    window.location.href = '/login';

    // window.location.reload();
  };
  
  return (
    <div className="w-screen h-screen">
      <div className="sm:p-10 w-full h-full flex justify-center">
        {/* <div>
          Choose a location to access its dashboard.
          {' '}
        </div> */}
        <div className="sm:w-[70%] xl:w-[50%] border rounded-[8px] bg-[#FAFBFD] relative">
          <div
            className=" h-[30%] px-[22px] pt-[22px] flex w-full sm:rounded-t-[8px] bg-[#12151A]"
          >
            {/* {profilePhoto === null  ? null : <img className="w-[50px] h-[50px] rounded-full absolute left-7" src={profilePhoto} alt="profile"/>} */}
            <div className="flex flex-col justify-between  w-full h-full">
              {/* <AiOutlineMessage className="text-[19px]" /> */}
              <div className="space-y-1 text-white">
                <div className="text-[22px] medium ">Select booking location </div>
                <div className="text-[14px] ">Choose or Search for the store location you want to book appointment.</div>
              </div>
              {/* <div className="px-[16px] break-words text-[14px] leading-[20px]">{settings?.header_message}</div> */}
              <div className="h-[83px] rounded-t-md bg-white flex items-center justify-center">
                <TextField
                // className={classes.myTextfield}                
                  inputProps={{
                    placeholder: 'store location or store name',
                    style: {
                      fontSize: '13px',
                      paddingLeft: '14px',
                    //   fontStyle: 'italic',
                    },
                  }}
                  sx={{
                    width: '85%' ,
                    '& .MuiInputBase-input': {
                      color: 'black',
                      borderBottom: '1px solid #E9E3FE',
                      fontSize: '14px',
                      transition: 'border-color 0.3s ease',
                      padding: '12px',
                    },
                    '& .MuiInputBase-input:focus': {
                      borderColor: '#3074DC',
                      borderBottom: '1px solid #3074DC',
                      outline: 'none',
                    },
                    '& .MuiFormLabel-root': {
                      paddingLeft: '5px',
                      fontSize: '15px',
                    },
                  }}
                  placeholder="store location or store name"
                  onChange={(e) => setSearchValue(e.target.value)}
                  id="standard-basic"
                  label="Search Locations"
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BiSearchAlt className="!text-[20px] text-[#3074dc]" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="absolute top-0 -left-[100px] xl:-left-[260px] hidden sm:flex  xl:flex items-center gap-2">
              <img src={logo} alt="" className="w-[80px] aspect-square rounded-full" />
              <span className="text-3xl medium hidden xl:block">Elyzian</span>
            </div>
            <div
              className="absolute right-1 top-1 sm:hidden pt-[8px] pr-[8px] text-[20px] leading-[28px]"
            >
              <MdClose />
            </div>
          </div>
          {/* <div>
            {data.map((e) => (
              <div 
                className="flex mt-2 cursor-pointer justify-center gap-[5px] items-center w-full text-[0.84rem] tracking-wide transition-all  focus:ring-[3px] hover:shadow 
              focus:bg-[#1E47F6]  hover:bg-[#1E47F6] hover:text-white  focus:ring-blue-200  text-gray-700 hover:transition hover:delay-200 buttonShadow  border-[0.5px] border-blue-700
              disabled:cursor-not-allowed rounded-lg shadow-sm medium p-2 disabled:bg-[#466eff8b] disabled:hover:shadow-none disabled:shadow-none"
                key={e._id}
                onClick={() => handleClick(e)}
              >
                {e.title}
              </div>
            ))}
          </div> */}
          <div className="px-[22px] h-[70%]">
            <div className="bg-white h-[80%] rounded-b-lg shadow-lg shadow-gray-300 p-[13px] overflow-y-auto space-y-2">
              {/* {searching ? (<div className="flex justify-center"><span className="loader2" /></div>) : (
                <> 
                  {' '} */}
              {data.length > 0 && data.filter((el) => (
                searchValue === '' ? el : el.title.toLowerCase().includes(searchValue.toLowerCase())
              ))?.map((e,index) => (
                <div
                  key={e._id}
                  onClick={() => handleClick(e)}
                  onMouseEnter={() => setHoverState(index)}
                  onMouseLeave={() => setHoverState(null)}
                  className="flex fade-in-bottom relative flex-col gap-2 p-2 cursor-pointer px-3 hover:bg-[#F1F5FB] rounded-lg"
                >
                  {/* {console.log('hover index',hoverState,'index',index)} */}
                  <span className={`flex items-center gap-3 medium text-sm ${hoverState === index ? 'text-[#3074dc]' : 'text-[#333333]'}`}>
                    <FaLocationDot /> 
                    {' '}
                    {e.title}
                  </span>
                  {/* <span className="text-[#595959] text-[13px]">
                    {e?.address?.zip} 
                    {' '}
                    {e?.address?.city} 
                    {' '}
                    {e?.address?.state_or_province }
                    {e?.address?.street_address.slice(0,18)}
                    ...
                  </span> */}
                  <BsArrowRightShort className={`text-[#3074dc] absolute right-2 text-[18px] ${hoverState === index ? 'visible' : 'invisible'} `} />
                </div>
              ))}
              {/* </> */}
              {/* )} */}
           
            </div>
          </div>
          <div  className="xl:flex hidden items-center gap-1 absolute top-0 -right-24 cursor-pointer medium hover:text-red-500">
            <MdClose />
            {' '}
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
