import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { IoMdClose,IoIosCloseCircle } from 'react-icons/io';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

export default function SimpleModalTwo({
  open,setOpen,children,height,width, padding,backdrop,
}) {
  const style = {
    position: 'absolute',
    bottom: '0%',
    left: '0%',
    // transform: 'translate(-50%, -50%)',
    width: width || 800,
    height,
    bgcolor: 'background.paper',
    borderRadius: '16px 16px 0px 0px',
    boxShadow: 24,
    p: padding || 4,
  };
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          {backdrop ? (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                // width: width || 800,
                // bgcolor: 'background.paper',
                // borderRadius: '16px',
                boxShadow: 24,
                p: padding || 4,
              }}
              className="w-screen  h-screen  p-4"
            >
              <div onClick={handleClose} className="h-10 flex justify-end cursor-pointer">
                {/* <div className=" flex items-center text-sm gap-1 text-white medium bg-red-600 h-max px-2 py-[2px] rounded-md border-1 border-red-800">
                  <IoMdClose className="text-white text-xl" />
                  Close
                </div> */}
              </div>
              <div className="h-[calc(100%-40px)] relative">
                <div onClick={handleClose} className="absolute h-max w-max flex justify-end cursor-pointer top-7 right-5">
                  <div className=" bg-white hover:bg-red-600 hover:text-white flex items-center justify-center text-sm gap-1  medium h-10 w-10 rounded-full px-2 py-[2px]  border-1 ">
                    <IoMdClose className="text-xl" />
                  </div>
                </div>
                {children}
              </div>
             
            </div>
          ) : (
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>
            <Box sx={style}>
              {children}
              <div onClick={handleClose} className="absolute h-max w-max flex justify-end cursor-pointer -top-9 right-4">
                <div className=" bg-white hover:bg-red-600 hover:text-white flex items-center justify-center text-sm   medium  rounded-full   border-1 ">
                  <IoIosCloseCircle className="text-2xl text-gray-400" />
                </div>
              </div>
            </Box>
            </Slide>
          )}
       
        </Fade>
      
      </Modal>
      
    </div>
  );
}
